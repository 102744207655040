import * as React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";

import {
  Layout,
  Section,
  Heading,
  SubHeading,
  SubSubHeading,
  NarrowContent,
  Header,
  SEO,
} from "../components";

const PrivacyPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO title={t("privacy_global.title")} />
      <NarrowContent>
        <Header />
        <Section>
          <Heading>Privacy Policy</Heading>
          <ReactMarkdown
            components={{
              h1: ({ node, ...props }) => <Heading {...props} />,
              h2: ({ node, ...props }) => <SubHeading {...props} />,
              h3: ({ node, ...props }) => <SubSubHeading {...props} />,
            }}
          >
            {t("privacy_global.body")}
          </ReactMarkdown>
        </Section>
      </NarrowContent>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { in: [$language, "en"] } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default PrivacyPage;
